import {
  WppBreadcrumb,
  WppIconChevron,
  WppIconDownload,
  WppSpinner,
  WppTypography,
} from '@wppopen/components-library-react'
import { useNavigate, useParams } from 'react-router-dom'

import { useUseCase } from 'api/queries/use-cases/useUseCase'
import { downloadFile } from 'utils/projectUtils'

import { UseCaseDetailsView } from './UseCaseDetailsView'

export const UseCasesEditView = () => {
  const { useCaseId } = useParams()

  const { data: useCase, isLoading } = useUseCase({ params: { id: useCaseId || '' } })

  const navigate = useNavigate()

  return (
    <div className="mt-7">
      <div className="flex mb-4 gap-2">
        <WppIconChevron direction="left" />
        <WppBreadcrumb
          onWppChange={event => navigate(event.detail.path)}
          items={[{ label: 'Back to Relevant Cases and Pitches', path: '/rfi-helper-tool/settings?view=usecases' }]}
        />
      </div>

      {isLoading ? (
        <div className="flex justify-center items-center h-full">
          <WppSpinner size="l" />
        </div>
      ) : (
        <>
          <div className="flex justify-between items-center mb-4">
            <WppTypography type="xl-heading">{useCase.title}</WppTypography>
            <WppIconDownload
              color="var(--color-font-blue-primary)"
              onClick={e => downloadFile(e, useCase.fileUrl)}
              className="cursor-pointer"
            />
          </div>
          <UseCaseDetailsView useCase={useCase} />
        </>
      )}
    </div>
  )
}
