import {
  WppAvatar,
  WppTypography,
  WppDivider,
  WppTooltip,
  WppCard,
  WppIconDownload,
  WppMenuContext,
  WppActionButton,
  WppIconMore,
  WppListItem,
  WppTag,
} from '@wppopen/components-library-react'
import { Fragment, ReactNode, SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react'

import { usePitchTypes } from 'api/queries/pitch-types/usePitchTypes'
import { useRfis } from 'api/queries/rfis/useRfis'
import { ProjectPreviewItem } from 'pages/home/ProjectPreviewItem'
import { useProjectFilterSelects } from 'pages/home/utils'
import { UseCase } from 'types/use-cases/useCase'

import style from './fileCmp.module.scss'

export default function FileCmp(props: {
  useCase: UseCase
  onClick: (useCase: UseCase) => void
  isAdmin?: boolean
  handleDeleteClick?: (id: string, name?: string) => void
}) {
  const { id, title, markets, pitchTypes, client, createdBy, subCategory, isConfidential } = props.useCase

  const { data: projects = [] } = useRfis({
    staleTime: 60 * 10 * 1000, // 10 minutes
  })

  const { data: allPitchTypes } = usePitchTypes({
    refetchOnWindowFocus: false,
  })

  let { clients } = useProjectFilterSelects(projects, allPitchTypes)

  const handleDeleteClick = props.handleDeleteClick || null

  const listContainerRef = useRef<HTMLDivElement>(null)
  const titleRef = useRef<HTMLDivElement | null>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [truncate, setTruncate] = useState(false)
  const [containerWidth, setContainerWidth] = useState(0)
  const rightSidePadding = 40

  const handleTruncate = useCallback(() => {
    setContainerWidth((containerRef.current as HTMLDivElement | null)?.clientWidth || 0)
    // get the width of the containerRef.current
    const containerWidth = (containerRef.current as HTMLElement | null)?.clientWidth || 0
    /*
     * Create an inline element to hold the text
     * and set the font style to match the span
     * so that the width of the span will match the width of the span
     * and we can get the width of the text
     * to determine if it needs to be truncated
     * NB: due to nested Web Components and shadow DOM issues
     */
    const span = document.createElement('span')
    // Set the font style of the span
    span.style.fontSize = '16px'
    span.style.opacity = '0'
    // Append the text to the span
    span.textContent = `${title}`
    // Append the span to the document body (it will be hidden)
    document.body.appendChild(span)
    // Get the width of the span
    const width = span.offsetWidth
    // Remove the span
    document.body.removeChild(span)

    if (width > containerWidth - rightSidePadding * 2) {
      setTruncate(true)
    }
  }, [title])

  useEffect(() => {
    if (titleRef?.current && containerRef?.current) {
      setTimeout(() => {
        handleTruncate()
      }, 150)
      window.addEventListener('resize', handleTruncate)
    }
    return () => {
      window.removeEventListener('resize', handleTruncate)
    }
  }, [containerWidth, handleTruncate])

  const Component = truncate
    ? ({ children }: { children: ReactNode }) => <WppTooltip text={title}>{children}</WppTooltip>
    : Fragment

  const handleContextClick = (e: SyntheticEvent) => {
    /* prevent bubbling up to main click event */
    e.preventDefault()
    e.stopPropagation()
  }

  return (
    <WppCard interactive className="w-[32%] h-[287px]" onClick={() => props.onClick(props.useCase)}>
      <div ref={containerRef as React.RefObject<HTMLDivElement>} className="flex flex-row justify-between relative">
        <div ref={titleRef}>
          <Component>
            <WppTypography
              type="xl-heading"
              className={style.headingTruncation}
              style={{
                width: `${containerWidth}px`,
              }}
            >
              {title}
            </WppTypography>
          </Component>
        </div>
        {props.isAdmin && handleDeleteClick && (
          <WppMenuContext className="w-1/5" onClick={handleContextClick}>
            <WppActionButton className="absolute top-2 right-2" slot="trigger-element">
              <WppIconMore direction="horizontal" />
            </WppActionButton>
            <div>
              <WppListItem onWppChangeListItem={() => handleDeleteClick(id, title)}>
                <p slot="label">Delete</p>
              </WppListItem>
            </div>
          </WppMenuContext>
        )}
      </div>
      <div className="mt-auto" ref={listContainerRef}>
        {isConfidential ? (
          <div className="mb-2">
            <WppTag label="Confidential file" variant="warning" />
          </div>
        ) : (
          <div className="mb-2">
            <WppTag label="Non-Confidential file" variant="warning" />
          </div>
        )}

        {[
          { name: 'Market:', value: markets.join(', ') },
          { name: 'Client:', value: clients.find(el => el.id === client)?.name || client },
          { name: 'Pitch Type:', value: pitchTypes.join(', ') },
        ].map((item, idx) => (
          <ProjectPreviewItem
            key={item.value + idx}
            labelText={item.name}
            value={item.value}
            containerWidth={listContainerRef?.current?.offsetWidth || 400}
          />
        ))}
        <ProjectPreviewItem
          labelText="Created By:"
          containerWidth={listContainerRef?.current?.offsetWidth || 400}
          value={createdBy}
        >
          <WppAvatar size="xs" className="m-h-6 m-w-6 flex-shrink-0 flex-grow-0 flex-auto" name={createdBy ?? ''} />
        </ProjectPreviewItem>
      </div>
      {(props.useCase.fileUrl || subCategory) && (
        <div className="flex flex-col gap-2 justify-center mt-1">
          <WppDivider />
          <div className="flex justify-between">
            {props.useCase.fileUrl && (
              <WppIconDownload
                className="hover:cursor-pointer flex items-center"
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  const link = document.createElement('a')
                  link.href = props.useCase.fileUrl
                  link.target = '_blank' // Open in a new tab

                  link.download = props.useCase.fileUrl?.split('/').pop() ?? ''

                  document.body.appendChild(link)

                  link.click()

                  document.body.removeChild(link)
                }}
              />
            )}
            {subCategory && (
              <span className="ml-auto text-[#058521] bg-[#63F783] px-2 py-1 rounded-[4px] text-sm">{subCategory}</span>
            )}
          </div>
        </div>
      )}
    </WppCard>
  )
}
