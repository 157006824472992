import {
  WppButton,
  WppDivider,
  WppFileUpload,
  WppInput,
  WppTypography,
  WppSelect,
  WppListItem,
  WppCheckbox,
} from '@wppopen/components-library-react'
import clsx from 'clsx'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { AddUseCaseParams } from 'api/fetchers/use-cases/createUseCase'
import { ReplaceUseCaseFileParams } from 'api/fetchers/use-cases/replaceUseCaseFile'
import { useCreateUseCase } from 'api/mutations/use-cases/useCreateUseCase'
import { useReplaceUseCaseFile } from 'api/mutations/use-cases/useReplaceUseCaseFile'
import { useUpdateUseCase } from 'api/mutations/use-cases/useUpdateUseCase'
import { useGetAgencies } from 'api/queries/agencies/useGetAgencies'
import { useMarkets } from 'api/queries/markets/useMarkets'
import { usePitchTypes } from 'api/queries/pitch-types/usePitchTypes'
import { useRfis } from 'api/queries/rfis/useRfis'
import { useTasksStatus } from 'api/queries/task-status/useTasksStatus'
import { queryClient } from 'app/Root'
import { ProgressApiRes } from 'components/LoaderProgressWithDescription'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useToast } from 'hooks/useToast'
import { useProjectFilterSelects } from 'pages/home/utils'
// import AgencyFileList from './agencyFileList/AgencyFileList'
import { UseCase } from 'types/use-cases/useCase'

import style from './UseCasesEditAdd.module.scss'

export interface Props {
  useCase?: UseCase
  handleCancel?: () => void
  handleSave?: (name?: string, description?: string) => void
}

export const UseCasesEditAdd = ({ useCase, handleCancel, handleSave }: Props) => {
  // Load filter data
  const { data: projects = [] } = useRfis({
    staleTime: 60 * 10 * 1000, // 10 minutes
  })

  const { mutateAsync: updateUseCase } = useUpdateUseCase()
  const { mutateAsync: updateUseCaseFile } = useReplaceUseCaseFile()

  const { data: markets = [] } = useMarkets()
  const { data: agencies = [] } = useGetAgencies()
  const { data: pitchTypes = [] } = usePitchTypes()
  let { clients } = useProjectFilterSelects(projects, pitchTypes)

  const subCategories = [
    'Context',
    'Client RFP and Briefs',
    'Pitch and RFP Responses',
    'Commercials',
    'Case Studies',
    'Competitor Intel',
  ]

  const [nameValue, setNameValue] = useState<string>(useCase?.title || '')
  const [descriptionValue, setDescriptionValue] = useState<string>(useCase?.title || '')
  const [clientNamesSelectValue, setClientNamesSelectValue] = useState<string>(useCase?.client || '')
  const [agencieSelectValues, setAgencieSelectValues] = useState<string[]>(useCase?.agencyIds || [])
  const [pitchTypeSelectValues, setPitchTypeSelectValues] = useState<string[]>(useCase?.pitchTypes || [])
  const [marketsSelectValues, setMarketsSelectValues] = useState<string[]>(useCase?.markets || [])
  const [subCategoriesSelectValues, setSubCategoriesSelectValues] = useState(useCase?.subCategory || '')

  const [wppFiles, setWppFiles] = useState<File[] | null>(null)
  const textAreaRef = useRef<HTMLWppTextareaInputElement>(null)

  const [disableSave, setDisableSave] = useState(!useCase?.title)
  const [task, setTask] = useState<ProgressApiRes | null>(null)
  const toast = useToast()
  const { data: taskStatus } = useTasksStatus({
    params: { taskId: task?.id || '' },
    enabled: !!task?.id,
    refetchInterval: wppFiles?.length ? 5000 : 2000,
  })
  const isEditMode = useCase ? 'edit' : 'add'

  const maxLength = 2000
  const { mutateAsync: addUseCase } = useCreateUseCase({
    onError: error => {
      toast.showToast({
        message: error.message,
        type: 'error',
      })
    },
  })

  const prepareRequest = useCallback((): AddUseCaseParams => {
    let request: AddUseCaseParams
    if (useCase?.id) {
      request = {
        params: {
          id: useCase?.id,
          title: nameValue,
          markets: marketsSelectValues,
          client: clientNamesSelectValue,
          agencyIds: agencieSelectValues,
          pitch_types: pitchTypeSelectValues,
          sub_category: subCategoriesSelectValues,
        },
      }
    } else {
      request = {
        params: {
          title: nameValue,
          markets: marketsSelectValues,
          client: clientNamesSelectValue,
          agencyIds: agencieSelectValues,
          pitch_types: pitchTypeSelectValues,
          sub_category: subCategoriesSelectValues,
        },
      }
    }

    if (wppFiles && !useCase) {
      const formData = new FormData()
      /* generate the files blob */
      const files = wppFiles.map(file => new Blob([file], { type: 'application/pdf' }))
      const file = files[0]
      formData.append('file', file, wppFiles[0]?.name)
      request = {
        formData,
        params: {
          title: nameValue,
          markets: marketsSelectValues,
          client: clientNamesSelectValue,
          agencyIds: agencieSelectValues,
          pitch_types: pitchTypeSelectValues,
          sub_category: subCategoriesSelectValues,
        },
      }
    }

    /*  */
    return request
  }, [
    agencieSelectValues,
    clientNamesSelectValue,
    marketsSelectValues,
    nameValue,
    pitchTypeSelectValues,
    subCategoriesSelectValues,
    useCase,
    wppFiles,
  ])

  const prepareRequestReplaceFile = useCallback((): ReplaceUseCaseFileParams | null => {
    let request: ReplaceUseCaseFileParams

    if (wppFiles) {
      const formData = new FormData()
      /* generate the files blob */
      const files = wppFiles.map(file => new Blob([file], { type: 'application/pdf' }))
      const file = files[0]
      formData.append('file', file, wppFiles[0]?.name)
      request = {
        formData,
        useCaseId: useCase?.id || '',
      }
    } else {
      return null
    }

    /*  */
    return request
  }, [useCase, wppFiles])

  const handleFileUploadChange = (event: CustomEvent) => setWppFiles(event.detail.value)
  const handleClearAll = () => {
    setNameValue('')
    setDescriptionValue('')
    setWppFiles(null)
  }

  const disableSaveMemo = useMemo(() => {
    if (
      nameValue &&
      clientNamesSelectValue &&
      agencieSelectValues.length &&
      pitchTypeSelectValues.length &&
      marketsSelectValues.length &&
      !disableSave &&
      !useCase &&
      wppFiles?.length &&
      wppFiles?.length > 0
    ) {
      return false
    } else if (
      nameValue &&
      clientNamesSelectValue &&
      agencieSelectValues.length &&
      pitchTypeSelectValues.length &&
      marketsSelectValues.length &&
      !disableSave &&
      useCase
    ) {
      return false
    } else {
      return true
    }
  }, [
    agencieSelectValues.length,
    clientNamesSelectValue,
    disableSave,
    marketsSelectValues.length,
    nameValue,
    pitchTypeSelectValues.length,
    useCase,
    wppFiles?.length,
  ])
  /*
   * For updating in the background we doing it on blur
   */
  const onHandleEdit = async () => {
    if (isEditMode && typeof useCase?.id !== 'undefined') {
      if (!nameValue) {
        return toast.showToast({
          message: 'Please enter a name for the agency',
          type: 'error',
        })
      }
      // const request = prepareRequest()

      try {
        await updateUseCase({
          id: useCase.id,
          title: nameValue,
          markets: marketsSelectValues,
          client: clientNamesSelectValue,
          agencyIds: agencieSelectValues,
          pitch_types: pitchTypeSelectValues,
          sub_category: subCategoriesSelectValues,
          isFavorite: useCase.isFavorite,
        })
        queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.USE_CASE] })
        toast.showToast({
          message: 'Use Case successfully updated',
          type: 'success',
        })
        // setTask(res.data)
        // handleSave && handleSave(nameValue, descriptionValue)
      } catch (e) {
        toast.showToast({
          message: 'Something went wrong while editing the use case. Please try again.',
          type: 'error',
        })
      }
    }
  }

  const handleEditFile = async () => {
    if (isEditMode && typeof useCase?.id !== 'undefined') {
      const request = prepareRequestReplaceFile()

      try {
        if (request === null) {
          toast.showToast({
            message: 'Something went wrong while editing the file. Please try again.',
            type: 'error',
          })
          return
        }
        const res = await updateUseCaseFile(request)
        if (wppFiles) {
          toast.showToast({
            message: 'Parsing files for processing...',
            type: 'information',
            duration: 5000,
          })
        }
        setTask(res.data)
        handleSave && handleSave(nameValue, descriptionValue)
      } catch (e) {
        toast.showToast({
          message: 'Something went wrong while editing the file. Please try again.',
          type: 'error',
        })
      }
    }
  }
  /*
   * error & success handling
   */
  const onHandleSave = useCallback(async () => {
    setDisableSave(true)
    let request = prepareRequest()

    if (!nameValue) {
      return toast.showToast({
        message: 'Please enter a name for the agency',
        type: 'error',
      })
    }

    try {
      const res = await addUseCase(request)
      if (wppFiles) {
        toast.showToast({
          message: 'Parsing files for processing...',
          type: 'information',
          duration: 5000,
        })
      }
      setTask(res.data)
      handleSave && handleSave(nameValue, descriptionValue)
    } catch (e) {
      toast.showToast({
        message: 'Something went wrong while adding the agency. Please try again.',
        type: 'error',
      })
    }
    setDisableSave(false)
    handleClearAll()
  }, [addUseCase, descriptionValue, handleSave, nameValue, prepareRequest, toast, wppFiles])

  const onHandleCancel = () => {
    handleClearAll()
    handleCancel && handleCancel()
  }

  // const handleCheckCharsOnPaste = (e: React.ClipboardEvent) => {
  //   const { clipboardData } = e
  //   const text = clipboardData.getData('text')
  //   if (text.length + descriptionValue.length > maxLength) {
  //     e.preventDefault()
  //     toast.showToast({
  //       message: `You can't paste more than ${maxLength} characters`,
  //       type: 'error',
  //     })
  //   }
  // }

  useEffect(() => {
    if (textAreaRef.current) {
      const shadowRoot = textAreaRef.current.shadowRoot
      const textArea = shadowRoot?.querySelector('textarea')
      textArea?.setAttribute('maxlength', maxLength.toString())
    }
  }, [descriptionValue])

  useEffect(() => {
    const { status, completed } = taskStatus || {}
    if (status === 'Parsing files for processing...') {
      toast.showToast({
        message: 'Parsing files for processing...',
        type: 'information',
        duration: 14000,
      })
    }
    if (status === 'failed') {
      toast.showToast({
        message: `Use case ${useCase ? 'edit' : 'creation'} was not successfull`,
        type: 'error',
      })
    }
    if (status?.includes('Error')) {
      toast.showToast({
        message: `Use case ${useCase ? 'edit' : 'creation'} was not successfull`,
        type: 'error',
      })
    }

    if (status === 'completed' || completed) {
      queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.USE_CASES, ApiQueryKeys.USE_CASE] }).then(() => {
        toast.showToast({
          message: `${!useCase ? "You've successfully added a new use case" : "You've successfully updated " + useCase + ' use case'}`,
          type: 'success',
        })
      })
      setTask(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useCase, taskStatus])

  useEffect(() => {
    setDisableSave(!nameValue)
  }, [nameValue])

  useEffect(() => {
    if (isEditMode) {
      // setWppFiles(null)
    }
  }, [isEditMode])

  // useEffect(() => {
  //   if (agency && agency.files !== agencyFiles) {
  //     setAgencyFiles(agency.files)
  //   }
  // }, [agency, agencyFiles])

  return (
    <>
      <div className="py-4">
        <WppInput
          name={nameValue}
          placeholder="Type use case name"
          required
          labelConfig={{
            text: 'Use Case Name',
          }}
          value={nameValue}
          //   onWppBlur={handleBlur}
          onWppChange={e => setNameValue(e?.detail?.value || '')}
        />
      </div>
      {/* <div className="py-4">
        <WppTextareaInput
          ref={textAreaRef}
          //   onWppBlur={handleBlur}
          labelConfig={{
            text: 'Description',
          }}
          onWppChange={e => setDescriptionValue(e?.detail?.value || '')}
          value={descriptionValue}
          charactersLimit={maxLength}
          maxMessageLength={maxLength}
          warningThreshold={maxLength - 100}
          content={descriptionValue}
          // onPaste={handleCheckCharsOnPaste}
          onPaste={handleCheckCharsOnPaste}
          className={style.textArea}
        />
      </div> */}
      {/* CLIENT */}
      <WppSelect
        aria-label="Client"
        title="Client"
        placeholder="Select client"
        type="single"
        labelConfig={{
          text: 'Client',
        }}
        withFolder
        withSearch
        value={clientNamesSelectValue}
        required
        onWppChange={e => {
          setClientNamesSelectValue(e.detail.value)
        }}
      >
        {clients?.map(item => (
          <WppListItem key={item.id} value={item.id}>
            <p slot="label">{item.name}</p>
          </WppListItem>
        ))}
      </WppSelect>
      {/* MARKETS */}
      <WppSelect
        aria-label="Markets"
        title="Markets"
        placeholder="Select markets"
        type="multiple"
        labelConfig={{
          text: 'Markets',
        }}
        withSearch
        withFolder
        value={[...marketsSelectValues]}
        required
        onWppChange={e => {
          setMarketsSelectValues(e.detail.value)
        }}
      >
        {markets?.map(item => (
          <WppListItem key={item.id} value={item.name}>
            <p slot="label">{item.name}</p>
          </WppListItem>
        ))}
      </WppSelect>
      {/* PITCH TYPES */}
      <WppSelect
        aria-label="Pitch type"
        title="Pitch type"
        placeholder="Select pitch type"
        type="multiple"
        labelConfig={{
          text: 'Pitch type',
        }}
        withSearch
        value={pitchTypeSelectValues}
        withFolder
        required
        onWppChange={e => {
          setPitchTypeSelectValues(e.detail.value)
        }}
      >
        {pitchTypes?.map(item => (
          <WppListItem key={item.id} value={item.typeDescription}>
            <p slot="label">{item.typeDescription}</p>
          </WppListItem>
        ))}
      </WppSelect>
      {/* AGENCIES */}
      <WppSelect
        aria-label="Agencies"
        title="Agencies"
        placeholder="Select agencies"
        labelConfig={{
          text: 'Agencies',
        }}
        type="multiple"
        withSearch
        withFolder
        value={agencieSelectValues}
        required
        onWppChange={e => {
          setAgencieSelectValues(e.detail.value)
        }}
      >
        {(agencies as { name: string; id: string }[])?.map(item => (
          <WppListItem key={item.id} value={item.id}>
            <p slot="label">{item.name}</p>
          </WppListItem>
        ))}
      </WppSelect>
      {/* SUB CATEGORIES */}
      <WppSelect
        aria-label="Subcategory"
        title="Subcategory"
        placeholder="Select Subcategory"
        labelConfig={{
          text: 'Subcategory',
        }}
        type="single"
        withSearch
        withFolder
        value={subCategoriesSelectValues}
        required
        onWppChange={e => {
          setSubCategoriesSelectValues(e.detail.value)
        }}
      >
        {subCategories?.map(item => (
          <WppListItem key={item} value={item}>
            <p slot="label">{item}</p>
          </WppListItem>
        ))}
      </WppSelect>

      <WppCheckbox
        required
        checked
        disabled
        labelConfig={{ text: 'Confidential file' }}
        className={clsx(useCase && 'mt-2')}
      />

      <div className="py-4">
        <div className="mb-2 flex flex-row items-center gap-1">
          <WppTypography type="s-strong" className={style.fileUploadtype}>
            File Upload
          </WppTypography>
        </div>
        <WppFileUpload
          name="description"
          acceptConfig={{ 'application/pdf': ['.pdf'] }}
          onWppChange={handleFileUploadChange}
          locales={{
            label: 'File upload',
            text: 'Drag and drop a file here or click to upload',
            info: (accept, size) => `Accepted file types: ${accept}. Max file size: ${size}MB`,
            sizeError: 'File Size Limit Exceeded',
            formatError: 'Unsupported file extension',
          }}
          onError={() => {
            toast.showToast({
              message: 'Your file upload was not successfull',
              type: 'error',
            })
          }}
          format="arrayBuffer"
          multiple={false}
          size={500}
        />
        {/*<AgencyFileList agencyFiles={agencyFiles} />*/}
      </div>
      <>
        {!useCase && <WppDivider />}
        <div className="flex ">
          <WppButton onClick={onHandleCancel} className="ml-auto mr-4" variant="secondary">
            Cancel
          </WppButton>
          {useCase && (
            <WppButton onClick={handleEditFile} className="mr-4" disabled={!wppFiles?.length}>
              Replace File
            </WppButton>
          )}

          <WppButton onClick={!useCase ? onHandleSave : onHandleEdit} className="mr-4" disabled={disableSaveMemo}>
            Save
          </WppButton>
        </div>
      </>
    </>
  )
}
