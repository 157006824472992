import { AttachmentMetadata, MayBeNull } from '@wppopen/core'

import { facadeApi } from 'api'

export interface Params {
  notification: NotificationAddDTO
}

export enum NotificationStatus {
  Scheduled = 'scheduled',
  Draft = 'draft',
  Sent = 'sent',
}

export interface NotificationBase {
  id: string
  tenantId: string
  title: string
  body: string
  status: NotificationStatus
  scheduledAt: MayBeNull<string>
  createdAt: string
  createdBy: string
  updatedAt: MayBeNull<string>
  updatedBy: MayBeNull<string>
}

export interface UserRecipient {
  email: string
  firstname: string
  lastname: string
  avatarOriginal: MayBeNull<AttachmentMetadata>
  avatarThumbnail: MayBeNull<AttachmentMetadata>
  isDeleted?: boolean
}

export interface GroupRecipient {
  uid: string
  name: string
  isDeleted?: boolean
}

export interface NotificationShort extends NotificationBase {
  recipients: {
    users: UserRecipient[]
    groups: string[]
  }
}

export interface Notification extends NotificationBase {
  recipients: {
    users: UserRecipient[]
    groups: GroupRecipient[]
  }
}

export type NotificationUpsertBase = Pick<NotificationShort, 'title' | 'body' | 'scheduledAt'> & {
  recipients: {
    users: string[]
    groups: string[]
  }
}

export interface NotificationAddDTO extends NotificationUpsertBase {
  tenantId: string
}

export const createNotification = ({ notification }: Params) =>
  facadeApi.post<Notification>('/notification-requests', notification)
