import { TabsChangeEventDetail } from '@wppopen/components-library'
import { WppTabsCustomEvent } from '@wppopen/components-library/dist/types/components'
import { WppIconFile, WppTab, WppTabs, WppTag, WppTypography } from '@wppopen/components-library-react'
import { useState } from 'react'

import { UseCase } from 'types/use-cases/useCase'
import { getFileNameFromUrl } from 'utils/projectUtils'

enum Tab {
  DETAILS = 'DETAILS',
}

export const UseCaseDetailsView = ({ useCase }: { useCase: UseCase }) => {
  const [currentTab, setCurrentTab] = useState(Tab.DETAILS)

  const handleTabChange = (event: WppTabsCustomEvent<TabsChangeEventDetail>) => {
    setCurrentTab(event.detail.value as Tab)
  }

  const { isConfidential, agencyIds, markets, client, fileUrl, pitchTypes, subCategory, summary } = useCase

  return (
    <div>
      <WppTabs value={currentTab} onWppChange={handleTabChange}>
        <WppTab value={Tab.DETAILS}>Details</WppTab>
      </WppTabs>
      {currentTab === Tab.DETAILS && (
        <div className=" flex flex-col gap-4 mt-5">
          {/*uncomment 👇 when working on the edit feature*/}
          {/* <div className="flex items-center justify-end">
            <WppButton variant="secondary">
              <div className="flex items-center gap-2">
                <WppIconEdit color="var(--color-font-blue-primary)" width={17} height={17} />
                <WppTypography type="s-strong" className="text-primary">
                  Edit
                </WppTypography>
              </div>
            </WppButton>
          </div> */}
          <WppTypography type="s-body">{summary}</WppTypography>
          <div>
            {isConfidential ? (
              <WppTag label="Confidential file" variant="warning" />
            ) : (
              <WppTag label="Non-Confidential file" variant="warning" />
            )}
          </div>
          {[
            { label: 'Agency', value: agencyIds },
            { label: 'Market', value: markets.join(', ') },
            { label: 'Client', value: client },
            { label: 'Pitch Types', value: pitchTypes.join(', ') },
            { label: 'Subcategory', value: subCategory },
          ].map((item, idx) => (
            <div className="flex gap-2" key={`${item.label}-${idx}`}>
              <WppTypography type="s-strong">{item.label}:</WppTypography>
              <WppTypography type="s-body">{item.value}</WppTypography>
            </div>
          ))}
          <WppTypography type="xl-heading">Uploaded Files</WppTypography>
          <div className="flex gap-2 bg-[#F4F5F7] rounded-6 p-3">
            <WppIconFile />
            <WppTypography type="s-body" className="underline text-[#343A3F]">
              {/*replace with file name from api after it's updated*/}
              {`${getFileNameFromUrl(fileUrl)}.pdf`}
            </WppTypography>
            <WppTypography type="s-body" className="text-[#697077]">
              {/* file size should be from the api */}
            </WppTypography>
          </div>
        </div>
      )}
    </div>
  )
}
